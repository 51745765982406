// 1. import `NextUIProvider` component
import '@/styles/global.scss';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import '@/styles/style.scss';

import { NextUIProvider } from '@nextui-org/react';
import type { AppProps } from 'next/app';
import { Suspense } from 'react';

import { lora, workSans } from '@/app/fonts';
import Loader from '@/components/Loader';
import { Header } from '@/components/header';
import { ScreenWidthProvider } from '@/components/ScreenWidthProvider';

const MyApp = ({ Component, pageProps }: AppProps) => (
  <>
    <Header />
    <main className={`${workSans.variable} ${lora.variable} tailwind-section`}>
      <Suspense fallback={<Loader />}>
        <ScreenWidthProvider>
          <NextUIProvider>
            <Component {...pageProps} />
          </NextUIProvider>
        </ScreenWidthProvider>
      </Suspense>
    </main>
  </>
);

export default MyApp;
