/* eslint-disable react/display-name */
import Link from '@/components/linkWithUTM';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
// import { FaUserCircle } from "react-icons/fa";
import { CONTENT_FOLDER_IDS } from '@constants';
import { navigateToLogin } from '@utils';

type MenuItem = {
  name: string;
  link?: string;
  submenu?: MenuItem[];
  subHeading?: {
    name: string;
    items?: MenuItem[];
    link?: string;
  }[];
  props?: any;
};

const MENU: MenuItem[] = [
  {
    name: 'Gurudev',
    link: '/us-en/gurudev',
  },
  {
    name: 'Explore',
    submenu: [
      {
        name: 'Breathwork',
        link: 'https://event.us.artofliving.org/us-en/breathwork2/lp1/',
      },
      {
        name: 'Meditation',
        link: 'https://event.us.artofliving.org/us-en/secrets-of-meditation2/lp1/',
      },
    ],
  },
  {
    name: 'Meditation',
    submenu: [
      {
        name: 'Daily SKY',
        link: 'https://members.us.artofliving.org/us-en/daily-sky',
      },
      {
        name: 'Guided Meditation',
        link: `https://members.us.artofliving.org/us-en/guided-meditation/${CONTENT_FOLDER_IDS.MEDITATE_FOLDER_ID}`,
      },
      {
        name: 'Meetups',
        link: 'https://members.us.artofliving.org/us-en/meetup',
      },
    ],
  },
  {
    name: 'Wisdom',
    submenu: [
      {
        name: 'Podcasts',
        link: 'https://members.us.artofliving.org/us-en/wisdom/podcast',
      },
    ],
  },
  {
    name: 'Courses',
    subHeading: [
      {
        name: "Beginner's Courses",
        items: [
          {
            name: 'Art Of Living Part 1',
            link: 'https://event.us.artofliving.org/us-en/artoflivingpart1',
          },
          {
            name: 'Sahaj Samadhi Meditation',
            link: `https://event.us.artofliving.org/us-en/sahajsamadhi`,
          },
          {
            name: 'Art Of Living Premium',
            link: 'https://event.us.artofliving.org/us-en/premiumcourse/',
          },
          {
            name: 'Sri Sri Yoga Foundation',
            link: 'https://members.us.artofliving.org/us-en/lp/online-foundation-program?utm_source=organic&utm_medium=home&utm_content=menu&course_id=1004431',
          },
        ],
      },
      {
        name: 'Advanced Courses',
        items: [
          {
            name: 'Art Of Living Part 2',
            link: 'https://event.us.artofliving.org/us-en/artoflivingpart2',
          },
          {
            name: 'Blessings',
            link: 'https://members.us.artofliving.org/us-en/lp/blessings-course',
          },
          {
            name: 'Chakra Kriya',
            link: 'https://members.us.artofliving.org/us-en/lp/chakra-kriya',
          },
          {
            name: 'DSN',
            link: 'https://members.us.artofliving.org/us-en/courses/dsn-course',
          },
          {
            name: 'Sanyam',
            link: 'https://members.us.artofliving.org/us-en/lp/sanyam',
          },
          {
            name: 'Shakti Kriya',
            link: 'https://members.us.artofliving.org/us-en/lp/shakti-kriya',
          },
          {
            name: 'Sri Sri Yoga Deep Dive',
            link: 'https://members.us.artofliving.org/us-en/lp/srisriyoga-deepdiveretreat',
          },
        ],
      },
      {
        name: 'Training Courses',
        items: [
          {
            name: 'Volunteer Training',
            link: 'https://members.us.artofliving.org/us-en/lp/vtp',
          },
          {
            name: 'Teacher Training',
            link: 'https://members.us.artofliving.org/us-en/lp/teacher-training-course',
          },
          {
            name: 'Sri Sri Yoga Teacher Training',
            link: 'https://artoflivingretreatcenter.org/event/sri-sri-school-of-yoga-ttc',
          },
          {
            name: 'Sri Sri Marma Practitioner',
            link: 'https://event.us.artofliving.org/us-en/marma-training',
          },
          {
            name: 'All Courses',
            link: 'https://members.us.artofliving.org/us-en/courses',
          },
          {
            name: 'Help me choose',
            link: 'https://members.us.artofliving.org/us-en/course-finder/welcome',
          },
        ],
      },
    ],
  },
  {
    name: 'Centers',
    submenu: [
      {
        name: 'Art of Living Boone Retreat',
        link: 'https://members.us.artofliving.org/us-en/lp/theartoflivingretreatcenter',
        props: { target: '_blank' },
      },
      {
        name: 'Connect Locally',
        link: 'https://members.us.artofliving.org/us-en/centers',
      },
      {
        name: 'Los Angeles',
        link: 'https://artoflivingla.org',
        props: { target: '_blank' },
      },
      {
        name: 'Washington DC',
        link: 'https://dc.artofliving.org',
        props: { target: '_blank' },
      },
    ],
  },
  {
    name: 'Resources',
    submenu: [
      {
        name: 'Meditations',
        link: `https://members.us.artofliving.org/us-en/library/${CONTENT_FOLDER_IDS.MEDITATE_FOLDER_ID}`,
      },
      {
        name: 'Wisdom',
        link: `https://members.us.artofliving.org/us-en/library/${CONTENT_FOLDER_IDS.WISDOM_FOLDER_ID}`,
      },
      {
        name: 'App',
        link: 'https://members.us.artofliving.org/us-en/lp/journey-app',
      },
    ],
  },
  {
    name: 'About',
    submenu: [
      {
        name: 'Art of Living',
        link: '/us-en/about-us',
      },
      {
        name: 'Service Projects',
        link: 'https://www.artofliving.org/us-en/service-projects-overview',
      },
      {
        name: 'Science',
        link: '/us-en/meditation/benefits/research-sudarshan-kriya',
      },
      {
        name: 'Blog',
        link: 'https://www.artofliving.org/us-en/blog',
      },
      {
        name: 'Press & Media',
        link: 'https://www.artofliving.org/us-en/media-coverage?search=',
      },
      {
        name: 'Testimonials',
        link: 'https://www.artofliving.org/us-en/testimonials/search',
      },
      {
        name: 'Contact Us',
        link: 'https://www.artofliving.org/us-en/contact-us',
      },
    ],
  },
];

export const Header: React.FC = () => {
  const router = useRouter();
  const [navExpanded, setNavExpanded] = useState<boolean>(false);

  const onToggleNav = () => {
    setNavExpanded(!navExpanded);
  };

  const renderMenu = (menu: MenuItem) => {
    if (!menu) return null;

    if (menu.submenu) {
      return (
        <>
          <button
            className="back-link dropdown-toggle"
            id="navbarCoursesDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              src="/img/BackArrow.svg"
              className="past-courses__cards__arrow back-link"
              alt="back arrow"
            />
          </button>
          <div className="dropdown-menu-col">
            {menu.submenu.map((submenu) => (
              <NavDropdown.Item
                href={submenu.link}
                key={submenu.name}
                as={Link}
                {...submenu.props}
              >
                {submenu.name}
              </NavDropdown.Item>
            ))}
          </div>
        </>
      );
    }

    if (menu.subHeading) {
      return (
        <>
          <button
            className="back-link dropdown-toggle"
            id="navbarCoursesDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              src="/img/BackArrow.svg"
              className="past-courses__cards__arrow back-link"
              alt="back arrow"
            />
          </button>
          <div className="dropdown-menu-inner">
            {menu.subHeading.map((subMenu) => (
              <React.Fragment key={subMenu.name}>
                {subMenu.items && (
                  <div className="dropdown-menu-col">
                    <h6 className="dropdown-header">{subMenu.name}</h6>
                    {subMenu.items.map((menuItem) => (
                      <NavDropdown.Item
                        href={menuItem.link}
                        key={menuItem.name}
                        className={
                          menuItem.link === '/us-en/courses'
                            ? 'active'
                            : menuItem.link === '/us-en/course-finder/welcome'
                              ? 'help'
                              : ''
                        }
                        as={Link}
                        {...menuItem.props}
                      >
                        {menuItem.name}
                      </NavDropdown.Item>
                    ))}
                  </div>
                )}
                {subMenu.link && (
                  <NavDropdown.Item
                    href={subMenu.link}
                    key={subMenu.name}
                    as={Link}
                    className="pt25"
                  >
                    {subMenu.name}
                  </NavDropdown.Item>
                )}
              </React.Fragment>
            ))}
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <header className={`header header-v2 ${navExpanded && 'menu-opened'}`}>
      <div className="header__container container">
        <Link href="/us-en" legacyBehavior>
          <a className="logo">
            <img src="/img/ic-logo.svg" alt="logo" className="logo__image" />
          </a>
        </Link>
        <Navbar
          expand="lg"
          onToggle={onToggleNav}
          expanded={navExpanded}
          collapseOnSelect
        >
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            as="button"
            className="navbar-toggler-header"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto" as="ul">
              {MENU.map((menu) => {
                if (menu.link) {
                  return (
                    <Nav.Item as="li" key={menu.name}>
                      <Nav.Link href={menu.link} as={Link} {...menu.props}>
                        {menu.name}
                      </Nav.Link>
                    </Nav.Item>
                  );
                }
                return (
                  <NavDropdown
                    title={menu.name}
                    key={menu.name}
                    as="li"
                    renderMenuOnMount
                  >
                    {renderMenu(menu)}
                  </NavDropdown>
                );
              })}
              <Nav.Item as="li" className="mobileView">
                <Nav.Link href="/us-en/lp/donations" as={Link}>
                  Donation
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="mobile-menu-buttons"></div>
          </Navbar.Collapse>
        </Navbar>
        <div className="user-profile-link">
          <div className="UserprofileView">
            {/* <button className="btn btn-outline header__button" type="button">
              Log In
            </button> */}
          </div>
        </div>
      </div>
    </header>
  );
};
