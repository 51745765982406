import React from 'react';

export default function Loader() {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <div className="flex items-center justify-center">
        <div className="size-16 animate-spin rounded-full border-y-4 border-gray-400" />
      </div>
    </div>
  );
}
