// Define the allowed parameters in a constant array
function getAllowedParams(): string[] {
  const allowedParams = [
    'adid',
    'ad_id',
    'adname',
    'ad_name',
    'adgroupid',
    'ad_group_id',
    'adsetid',
    'adset_id',
    'adsetname',
    'adset_name',
    'cpid',
    'campaignid',
    'campaign_id',
    'campaign_cta',
    'keyword',
    'placement',
    'site_source',
    'targetid',
    'utm_adgroup',
    'campaign_name',
    'utmcampaign',
    'utm_campaign',
    'utmcontent',
    'utm_content',
    'utmdevice',
    'utm_device',
    'utmlandingpage',
    'utm_landingpage',
    'utmmedium',
    'utm_medium',
    'utmsource',
    'utm_source',
    'utmterm',
    'utm_term',
    'sscid',
  ];

  return allowedParams;
}

// Filter allowed query parameters
export function filterAllowedParams(
  params: Record<string, any>
): Record<string, any> {
  if (params && Object.keys(params).length) {
    const filteredParams = Object.keys(params)
      .filter((key) => getAllowedParams().includes(key.toLowerCase()))
      .reduce((obj: Record<string, any>, key) => {
        obj[key] = params[key];
        return obj;
      }, {});

    return filteredParams;
  }

  return params || {};
}

// Remove null or empty string values from the object
export function removeNull(obj: Record<string, any>): Record<string, any> {
  return Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => {
        if (
          Array.isArray(value) &&
          value.length > 0 &&
          typeof value[0] === 'string'
        ) {
          // If it's an array of strings, use the first element as the value
          return [key, value[0]];
        } else if (value !== null && String(value).trim() !== '') {
          // If it's not null or an empty string, keep the value
          return [key, value];
        }
        // Otherwise, skip this key-value pair
        return null;
      })
      .filter((pair): pair is [string, any] => pair !== null) // Ensure non-null pairs
  );
}
