import { filterAllowedParams } from '@utils/utmParam';
import appendQuery from 'append-query';
import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { ReactNode } from 'react';

interface CustomLinkProps extends Omit<LinkProps, 'href'> {
  children: ReactNode;
  href: string;
}

export default function Link({ children, href, ...rest }: CustomLinkProps) {
  const router = useRouter();

  // Filter the allowed query parameters
  const filteredParams = filterAllowedParams(router.query);
  const allParams = {
    ...filteredParams,
  };

  // Append UTM params to the href
  const urlWithUTM = appendQuery(href, queryString.stringify(allParams));

  return (
    <NextLink {...rest} href={urlWithUTM} passHref>
      {children}
    </NextLink>
  );
}
