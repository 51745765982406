import React, { createContext, useContext, useEffect, useState } from 'react';

const ScreenWidthContext = createContext(0);

export function useScreenWidth() {
  return useContext(ScreenWidthContext);
}

export interface Props {
  children?: React.ReactNode;
}
export function ScreenWidthProvider({ children }: Props) {
  const [screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Initial screen width
    setScreenWidth(window.innerWidth);

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ScreenWidthContext.Provider value={screenWidth}>
      {children}
    </ScreenWidthContext.Provider>
  );
}
